import api from '@/api'
import * as storage from '@/storage'
import uniq from 'lodash/uniq'

const state = {
    list: [],
    tags: [],
    filterOptions: [],
    selectedColumns: [],
}

const mutations = {
    RESET(state) {
        state.list = []
        state.tags = []
        state.filterOptions = []
        state.selectedColumns = []
    },
    SET_CONTACTS(state, data) {
        state.list = data
    },
    SET_TAGS(state, data) {
        state.tags = data
    },
    SET_FILTER_OPTIONS(state, data) {
        state.filterOptions = data
    },
    SET_CONTACTS_SELECTED_COLUMNS(state, data) {
        state.selectedColumns = data
    },
    SET_PRESENCE(state, data) {
        state.presence = data
    },
}

const actions = {
    createContact({ dispatch }, contact) {
        return api
            .post('/contacts', {
                contact,
            })
            .catch(e => dispatch('handleError', e))
    },
    updateContact({ dispatch }, contact) {
        return api
            .patch(`/contacts/${contact.id}`, {
                contact,
            })
            .then(() => {
                dispatch('getContact', contact.id)
            })
            .catch(e => dispatch('handleError', e))
    },
    linkContacts({ dispatch }, { id, key, parentId }) {
        return api
            .patch(`/contacts/${id}`, {
                contact: { id, [key]: parentId },
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteContact({ dispatch }, id) {
        return api.delete(`/contacts/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    getContactsBlockList({ dispatch }, params = {}) {
        const { pagination, search } = params

        return api
            .get(`/blocklist`, { params: { ...pagination, search } })
            .catch(e => dispatch('handleError', e))
    },
    blockContact({ dispatch }, { id, ...data }) {
        return api.post(`/blocklist/${id}`, data).catch(e => dispatch('handleError', e))
    },
    unblockContact({ dispatch }, id) {
        return api.delete(`/blocklist/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    getContacts({ commit, dispatch }, params = {}) {
        let { pagination, search, tags, filters, list } = params
        tags = tags ? tags.join(',') : ''
        pagination ? delete pagination.totalItems : false
        return api
            .get('/contacts', { params: { ...pagination, search, tags, filters, list } })
            .then(response => {
                commit('SET_CONTACTS', response)
                return response
            })
            .catch(e => dispatch('handleError', e))
    },
    getContactsCount({ dispatch }, params = {}) {
        let { search, tags, filters } = params
        tags = tags ? tags.join(',') : ''
        return api
            .get('/contacts', { params: { search, tags, filters } })
            .then(response => {
                return response.total || (response.meta && response.meta.total)
            })
            .catch(e => dispatch('handleError', e))
    },
    getContact({ dispatch }, id) {
        return api
            .get(`/contacts/${id}`)
            .then(({ data }) => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getContactAddresses({ dispatch }, id) {
        return api
            .get(`/addresses/contact/${id}`)
            .then(({ addresses }) => {
                return addresses
            })
            .catch(e => dispatch('handleError', e))
    },
    createContactAddress({ dispatch }, address) {
        const url = `/addresses/contact/${address.contactId}`
        delete address.contactId
        return api
            .post(url, {
                address,
            })
            .catch(e => dispatch('handleError', e))
    },
    updateContactAddress({ dispatch }, address) {
        const url = `/addresses/contact/${address.contactId}/${address.id}`
        delete address.contactId
        return api
            .put(url, {
                address,
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteContactAddress({ dispatch }, address) {
        return api
            .delete(`/addresses/contact/${address.contactId}/${address.id}`, {})
            .catch(e => dispatch('handleError', e))
    },
    getCreatedTrend({ commit, dispatch }) {
        return api
            .get('/contacts/trends/created')
            .then(({ trend }) => {
                commit('SET_CREATED_TREND', trend)
                return trend
            })
            .catch(e => dispatch('handleError', e))
    },
    getContactsTags({ commit, dispatch }) {
        return api
            .get('/contacts/tags/list')
            .then(({ tags }) => {
                commit('SET_TAGS', tags)
                return tags
            })
            .catch(e => dispatch('handleError', e))
    },
    getContactTransactions({ dispatch }, { id, pagination, venue }) {
        return api
            .get(`/contacts/${id}/transactions`, { params: { ...pagination, venue } })
            .catch(e => dispatch('handleError', e))
    },
    getCompanyContacts({ dispatch }, { id, pagination }) {
        return api
            .get(`/contacts/${id}/contacts`, { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
    getAssociatedContacts({ dispatch }, { id, pagination }) {
        return api
            .get(`/contacts/${id}/associates`, { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
    getContactWalletPasses({ dispatch }, { id, pagination, venue }) {
        return api
            .get(`/contacts/${id}/passes`, { params: { ...pagination, venue } })
            .catch(e => dispatch('handleError', e))
    },
    getContactsFilterOptions({ commit, dispatch, state }, field) {
        const filterOptions =
            Array.isArray(state.list.contacts) &&
            uniq(state.list.contacts.map(o => o[field])).filter(v => !!v)
        return Promise.resolve({ filterOptions })
            .then(({ filterOptions }) => {
                commit('SET_FILTER_OPTIONS', filterOptions)
                return filterOptions
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteContacts({ dispatch }, contacts) {
        return api
            .put('/contacts/bulk/delete', { contacts })
            .catch(e => dispatch('handleError', e))
    },
    addContactsTags({ dispatch }, { ids, tags }) {
        return api
            .put('/contacts/tags/add', { contacts: ids, tags })
            .catch(e => dispatch('handleError', e))
    },
    removeContactsTags({ dispatch }, { ids, tags }) {
        return api
            .put('/contacts/tags/delete', { contacts: ids, tags })
            .catch(e => dispatch('handleError', e))
    },
    setContactsSelectedColumns({ commit }, selectedColumns) {
        try {
            storage.local.setItem('contacts.selectedColumns', JSON.stringify(selectedColumns))
            commit('SET_CONTACTS_SELECTED_COLUMNS', selectedColumns)
            return selectedColumns
        } catch (e) {
            return selectedColumns
        }
    },
    getContactsSelectedColumns({ commit }) {
        const defaultValue = ['email', 'phone', 'source', 'status', 'created_at']
        try {
            const selectedColumns = JSON.parse(
                storage.local.getItem('contacts.selectedColumns')
            )
            commit(
                'SET_CONTACTS_SELECTED_COLUMNS',
                Array.isArray(selectedColumns) ? selectedColumns : defaultValue
            )
            return selectedColumns
        } catch (e) {
            return defaultValue
        }
    },
    // changePlan({ dispatch }, { id, plan }) {
    //     return api
    //         .put(`/contacts/${id}/changeplan`, { plan })
    //         .catch(e => dispatch('handleError', e))
    // },
    cancelPlan({ dispatch }, { contact_id, id, now }) {
        return api
            .post(`/contacts/${contact_id}/cancelplan`, { id, now })
            .catch(e => dispatch('handleError', e))
    },
    resumePlan({ dispatch }, { contact_id, id }) {
        return api
            .patch(`/contacts/${contact_id}/resumeplan`, { id })
            .catch(e => dispatch('handleError', e))
    },
    exportContacts({ dispatch }, params = {}) {
        let tags = params.tags ? params.tags.join(',') : ''
        params.tags = tags

        return api.post('/contacts/export/csv', params).catch(e => dispatch('handleError', e))
    },
    getContactActivity({ dispatch }, { id, pagination }) {
        return api
            .get(`/contacts/${id}/activity`, { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
    getContactsPresenceCheckedIn({ dispatch }, { projectId, type = 'product', pagination }) {
        return api
            .get(`/presence/${projectId}/${type}/checkedin`, { params: { ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
